import { ref } from 'vue';

const DEFAULT_BREAKPOINTS = {
    xs: el => el.width < 375,
    sm: el => el.width >= 375 && el.width < 600,
    md: el => el.width >= 600 && el.width < 900,
    lg: el => el.width >= 900 && el.width < 1200,
    xl: el => el.width >= 1200,
    tablet: el => el.width < 900,
    mobile: el => el.width < 600,
    desktop: el => el.width >= 900,
};
const DEFAULT_VALUES = {
    el: null,
    width: 0,
    height: 0,
    prefix: '',
    breakpoints: { ...DEFAULT_BREAKPOINTS }
};
export const DEFAULT_CAPSULE = {
    is: {
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: true,
        tablet: false,
        mobile: false,
        desktop: true
    }
};
export const elements = ref({});
export const viewport = ref({ ...DEFAULT_VALUES, is: {} });
export const capsule = ref({ ...DEFAULT_VALUES, is: {} });

export const onResize = (instance, entries = null) => {
    requestAnimationFrame(() => {
        if (entries && entries.length) {
            const { width, height } = entries[0].contentRect;
            instance.width = width;
            instance.height = height;
        } else {
            instance.width = instance.el.clientWidth;
            instance.height = instance.el.clientHeight;
        }
        if (instance.width === 0 && instance.height === 0) return;
        if (instance.prefix) instance.is = {};
        for (const key in instance.breakpoints) {
            instance.is[`${instance.prefix}${key}`] = instance.breakpoints[key](instance);
        }
    });
};

export const destroy = (name) => {
    let instance = elements.value[name];
    if (name === 'viewport') instance = viewport.value;
    if (name === 'capsule') instance = capsule.value;
    instance.value.observer.unobserve(viewport.value.el);
};

export const useResponsive = (name = 'viewport', el = null, breakpoints = null) => {
    // First fetch the responsive instance
    if (typeof name !== 'string') name = getComponentUid(name);
    let instance = elements.value[name];

    // If the element passed is a Vue component, get the actual element
    if (el && el.$el) el = el.$el;

    if (name === 'viewport') {
        instance = viewport;
        if (el instanceof Element) instance.value.el = el;
        if (!instance.value.el) instance.value.el = document.documentElement;
    } else if (name.includes('capsule')) {
        if (name === 'capsule') {
            instance = capsule;
        } else {
            instance = ref({ ...DEFAULT_VALUES, is: {}, el, name });
        }
        if (el instanceof Element) instance.value.el = el;
        if (!instance.value.el || !document.documentElement.contains(instance.value.el)) {
            instance.value.el = document.querySelector('main') || document.querySelector('.capsule-wrapper');
            instance.value.isObserved = false;
        }
    } else if (el instanceof Element && !instance) {
        instance = ref({ ...DEFAULT_VALUES, is: {}, el, name });
        elements.value[name] = instance.value;
    }

    // If we somehow have nothing here, return the default values
    if (!(instance && instance.value)) return { ...DEFAULT_VALUES, is: {} };

    if (breakpoints) instance.value.breakpoints = breakpoints;
    if (!instance.value.observer) instance.value.observer = new ResizeObserver((...args) => onResize(instance.value, ...args));
    if (instance.value.el && !instance.value.isObserved) {
        instance.value.observer.observe(instance.value.el);
        instance.value.isObserved = true;
        onResize(instance.value);
    }
    return instance.value;
};

const getComponentUid = component => `${component.$options && component.$options.name || 'Component'}_${component._.uid}`;
export const getElement = component => elements.value[getComponentUid(component)] || { ...DEFAULT_VALUES, is: {} };
export const removeElement = component => {
    const instance = elements.value[getComponentUid(component)];
    if (instance && instance.observer) instance.observer.unobserve(instance.el);
    delete elements.value[getComponentUid(component)];
};

useResponsive.getElement = getElement;
useResponsive.removeElement = removeElement;
